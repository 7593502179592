import React, { useState, useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header"
import NavBar from "./Components/Nav/Navbar";
import Homepage from "./Components/Homepage/Homepage";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import PostCard from "./Components/Carousel/PostCard"
import Footer from "./Components/Footer/Footer"
// import TermsConditions from "./Components/TermsConditions/TermsConditions";
import CarrierRegistration from "./Components/CarrierRegistration/CarrierRegistration";


function App() {
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {

    const onLoadEvent = async () => {
      setInfoLoaded(true);
    }

    setInfoLoaded(false);
    onLoadEvent();
  }, [])

  if (!infoLoaded) return <span data-testid="loading">Loading ...</span>;

  return (
    <HashRouter>
      <div className="App">
        <Header />
        <NavBar />
        <PostCard />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          {/* <Route path="/terms-conditions" element={<TermsConditions />} /> */}
          <Route path="/carrier-registration" element={<CarrierRegistration />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
