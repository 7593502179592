import React from 'react'
import { Autoplay, Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, Grid } from "@mui/material"
import nightSkyline from "./nightSkyline.jpg"
import horses from "./horses.jpg"
import skyline from "./skyline.jpg"
import "./PostCard.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const images = [skyline, nightSkyline, horses]

const PostCard = () => {
    return (
        <Container maxWidth="lg" sx={{ px: "0px" }}>
            <Grid container
                sx={{ mb: "16px" }}>
                <Grid item xs={12} >
                    <Swiper
                        grabCursor
                        modules={[Autoplay, Navigation, Pagination, A11y]}
                        keyboard={{ enabled: true }}
                        pagination={{ clickable: true }}
                        navigation={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                        loop
                        spaceBetween={30}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img src={image} className="slider-image" alt="" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Grid>
            </Grid>
        </Container>

    )
}

export default PostCard;