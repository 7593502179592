import React from 'react'
import { Container, Box, Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blue, blueGrey } from '@mui/material/colors';



const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: blue[900],
            light: blue[700],
            dark: '#002171',
        },
        secondary: {
            // This is green.A700 as hex.
            main: blueGrey[50],
            light: '#ffffff',
            dark: blueGrey[200],
        },
    },
});

const Footer = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box
                mt={{ xs: 6, sm: 8}}
                py={{ xs: 4, sm: 6 }}
                bgcolor="primary.main"
            >
                <Container maxWidth="lg">
                    <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                        <Grid item xs={3} sm={2}>
                            <Link to="/contact-us" color="secondary.light">Contact Us</Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default Footer;