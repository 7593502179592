import React from "react";
import { Container, Typography, Link, Grid, Paper } from "@mui/material";

const Contact = () => {
    return (
        <Container maxWidth="md">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }} sx={{ mb: "8px" }}>
                <Grid item xs={6} sx={{ p: "0px" }}>
                    <Paper elevation={0} square sx={{ my: "20px" }} >

                        <Typography variant="h4">Address</Typography>
                        <Typography variant="body1">
                            713 Highland Park Dr. Hurst, Texas 76054
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={0} square sx={{ my: "20px" }} >
                        <Typography variant="h4">Email</Typography>
                        <Link href={"mailto:info@buddy-broker.com"} variant="h6" sx={{ py: "10px" }}>info@buddy-broker.com</Link>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mb: "40px" }}>
                <Grid item xs={6}>
                    <Paper elevation={0} square sx={{ p: "5px", my: "20px" }} >
                        <Typography variant="h4">Phone</Typography>
                        <Typography variant="body1">Office:&nbsp; 
                            <Link href={"tel:6822496201"} variant="body1">
                                682-249-6201
                            </Link>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={0} square sx={{ p: "5px", my: "20px" }} >
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <Typography variant="h4">President</Typography>
                                <Typography nowrap="true" variant="body1">
                                    Garrett Miller
                                </Typography>
                            </Grid>
                        </Grid>
                        <Link href={"mailto:garrett@buddy-broker.com"} sx={{ pt: "10px" }}>garrett@buddy-broker.com</Link>
                        <Typography variant="h4" sx={{ pt: "20px" }}>Accounting</Typography>
                        <Typography variant="body1">
                            Melissa Henderson
                        </Typography>
                        <Link href={"mailto:melissa@buddy-broker.com"} sx={{ pt: "20px" }}>melissa@buddy-broker.com</Link>
                    </Paper>
                </Grid>
            </Grid>
        </Container >
    );
}

export default Contact;