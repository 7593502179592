import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Container, Box, IconButton, Menu, MenuItem, Button } from '@mui/material';
import "./Navbar.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { blue, blueGrey } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: blue[900],
            light: blue[700],
            dark: '#002171',
        },
        secondary: {
            // This is green.A700 as hex.
            main: blueGrey[50],
            light: '#ffffff',
            dark: blueGrey[200],
        },
    },
});


const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar color="primary" position="static">
                <Container maxwidth="md">
                    <Toolbar disableGutters maxwidth="md">
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, }}>
                            <IconButton
                                size="large"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Link className="MenuItem" to='/' textalign="center">Services</Link>
                                </MenuItem>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Link className="MenuItem" to='/about' textalign="center">About</Link>
                                </MenuItem>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Link className="MenuItem" to='/contact-us' textalign="center">Contact Us</Link>
                                </MenuItem>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Link className="MenuItem" to='/carrier-registration' textalign="center">Carrier Registration</Link>
                                </MenuItem>
                                {/* <MenuItem onClick={handleCloseNavMenu}>
                                    <Link className="MenuItem" to='/terms-conditions' textalign="center">Terms & Conditions</Link>
                            </MenuItem> */}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "space-around" }}>
                            <Link to='/' textalign="center">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    variant="outlined"
                                    size="large"
                                >
                                    Services
                                </Button>
                            </Link>
                            <Link to='/about' textalign="center">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    variant="outlined"
                                    size="large"
                                >
                                    About
                                </Button>
                            </Link>
                            <Link to='/contact-us' textalign="center">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    variant="outlined"
                                    size="large"
                                >
                                    Contact Us
                                </Button>
                            </Link>
                            {/* <Link to='/terms-conditions' textalign="center">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    variant="outlined"
                                    size="large"
                                >
                                    Terms & Conditions
                                </Button>
                            </Link>
                            */ }
                            <Link to='/carrier-registration' textalign="center">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    variant="outlined"
                                    size="large"
                                >
                                    Carrier Registration
                                </Button>
                            </Link>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    )
}

export default NavBar;