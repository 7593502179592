import React from "react";
import fleet from "./fleet.jpg"
import "./About.css";
//import { useNavigate } from "react-router-dom";
import { Box, Container, Paper, Grid, Typography } from "@mui/material";


const About = () => {
    //const navigate = useNavigate();
    //if(currentUser) history.push(`/menus`)
    return (
        <Container maxWidth="md">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
                <Grid item xs={6}>
                    <Paper square elevation={0}>
                        <Typography variant="h3">About us</Typography>
                        <Typography variant="body1" sx={{ p: "5px", mt: "10px" }}>We provide services that arrange for the transportation of truckload shipments for companies in North America, and across various industry segments. We provide simple solutions within the supply chain and deliver remarkable service. Our company was founded in 2022 by Melissa Henderson. In the beginning, Melissa focused her attention to the carrier side of the business and built a reputation as a reliable service provider. </Typography>
                        <Typography variant="body1" sx={{ p: "5px", mt: "10px" }}>Buddy Brokerage provides great rates and services with our office located in Dallas, Fort Worth, Texas. At Buddy Brokerage, we take pride in putting performance ahead of promises. We consistently meet our commitments and deadlines to exceed customer satisfaction.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Box mt={{ xs: 0, sm: 12 }}
                        >
                        <img src={fleet} alt="Buddy Broker Fleet" />
                    </Box>

                </Grid>
            </Grid>
        </Container>
    );
};

export default About;