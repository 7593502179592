import React from "react";
//import { useNavigate } from "react-router-dom";
import { Container, Box, Paper, Typography, Grid } from "@mui/material";
import "./Homepage.css";
import tractor from "./tractor.jpg"


const Homepage = () => {
    //const navigate = useNavigate();
    //if(currentUser) history.push(`/menus`)
    return (
        <Container maxWidth="md">
            <Grid container spacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, sm: 6, md: 12 }}>
                <Grid item xs={6}>
                    <Paper elevation={0} square>
                        <Typography variant="h3" >Services</Typography>
                        <Typography variant="body1" sx={{ p: "5px", mt: "10px" }}>Buddy Brokerage, Inc. is a dedicated transportation brokerage service that provides our customers with the best. Our commitment to excellence is a core value that sets us apart from other freight companies. We specialize in full truckload movements and have the ability to handle your less-than-truckload needs, as well as specialized services.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6} >
                    <Box mt={{ xs: 0, sm: 4 }}>
                        <img src={tractor} alt="Tractor Trailer" />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Homepage;