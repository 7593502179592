import React from "react";
import logo from "./buddy_logo_header.jpg"
import "./Header.css";
//import { useNavigate } from "react-router-dom";
import { Container, Paper, Grid } from "@mui/material";
import { Link as Tag } from "react-router-dom";


const logoSize = {
    width: "100%",
    height: "auto",
}

const grid = {
    justifyContent: "center",
    alignItems: "center"
}

const Homepage = () => {
    //const navigate = useNavigate();
    //if(currentUser) history.push(`/menus`)
    return (
        <Container maxWidth="md">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8, sm: 12, md: 12 }} sx={{...grid}}>
                <Grid item xs={6}>
                    <Tag to="/">
                        <Paper elevation={0} square sx={{ ...logoSize }}>

                            <img src={logo} alt="" />

                        </Paper>
                    </Tag>
                </Grid>
            </Grid>


        </Container>
    );
};

export default Homepage;