import React from "react";
//import { useNavigate } from "react-router-dom";
import { Container, Typography, Link, Grid, Paper } from "@mui/material";
import pdf from './CarrierPacket.pdf';

const CarrierRegistration = () => {
    return (
        <Container maxWidth="md">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12}>
                    <Paper elevation={0} square sx={{ mb: "20px" }} >
                        <Typography variant="h3">Carrier Registration</Typography>
                        <Typography variant="body1">Thank you for your interest in becoming a qualified carrier for Buddy Brokerage, Inc! We are excited to add your company to our list of approved carriers.
                        </Typography>
                        <Link variant="body1" href={pdf}>Carrier Packet (PDF)</Link>

                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12}>
                    <Paper elevation={0} square sx={{ mt: "20px" }} >
                        <Typography variant="h3">Proof of Insurance</Typography>
                        <Typography variant="body1">
                            Please fax or email your current insurance certificate to:
                        </Typography>
                        <Typography variant="body1">
                            Fax: 817-479-8413
                        </Typography>
                        <Typography variant="body1">Email:&nbsp; 
                            <Link href={"mailto:info@buddy-broker.com"} sx={{ pt: "20px" }}>info@buddy-broker.com</Link>
                        </Typography>
                        <Typography variant="body1">
                            Buddy Brokerage Inc. MUST receive this certificate, before we can send carrier a rate confirmation.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

        </Container>
    );
}

export default CarrierRegistration;